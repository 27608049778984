const config = {
  header: "ForexCards.xyz",
  headerBackground: "#ff8ba7",
  bodyBackground: "#ffc6c7",
  title: "Forex Travel Card for Indians - ✈️ Travel - 📚 Education",
  subTitle: "🚨For safety we would recommend you to always choose two cards 😊",
  airtable: "https://airtable.com/embed/shrh6EAvp35Ma3iN4?backgroundColor=cyan&viewControls=on",
  googleAnalytics: "UA-31455093-3"
}

export default config